<template>
    <div class="Sigsimu">
        <p class="p justify">
            VGMS 可用于几何体模型的可视化展示，构建不同尺寸的几何体模型，通过灯光、材质、渲染实现模型的可视化效果呈现。
        </p>
        <div class="vgms">
            <a href="https://www.tianyandonghua.com/face_Visualization/"><el-button type="primary" round>face_Visualization</el-button></a>
            <a href="https://www.tianyandonghua.com/cube_Visualization/"><el-button type="primary" round>cube_Visualization</el-button></a>
            <a href="https://www.tianyandonghua.com/ball_Visualization/"><el-button type="primary" round>ball_Visualization</el-button></a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Sigsimu',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', 'VGMS')
      }
}
</script>
<style lang="less" scoped>
.Sigsimu{
    margin-bottom:6.25rem;
}
a{
    color: black;
}
.vgms{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    a{
        margin-top: 10px;
    }
}
@media screen and (min-width:990px) and (max-width:1030px){
  .vgms{
    width: 333px;
  }
}
@media screen and (min-width:0px) and (max-width:575px){
  .vgms{
    width: 333px;
    margin: 0 auto;
  }
}

</style>
